@mixin state_headed() {
	.content {
		padding: 0 8px 48px;
	}

	.header {
		display: flex;
		fill: #fff;
		align-items: center;
		padding-left: 16px;
		padding-right: 16px;
		height: 46px;
	}

	.closeBtn {
		left: auto;
		top: auto;
		bottom: 100%;
		right: 0;
		background: none;
		margin-bottom: 3px;
		margin-right: 16px;
		@include hover(hover) {
			&:hover {
				background-color: none;

				.closeIcon {
					fill: #ef1d4f;
				}
			}
		}
	}
}
