@use 'sass:math';
@import 'meta';
@import './states/fullscreen.scss';
@import './states/headed.scss';
@import './animation/appear.scss';

.block {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	z-index: 1000;
	overflow-y: scroll;
	overflow-x: hidden;
	background: rgba(0, 0, 0, 0.64);
}

.bg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.closer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.wrap {
	position: relative;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	&_centred {
		justify-content: center;
	}
}

.content {
	position: relative;
	min-width: 0;
	max-width: 100%;
	padding: 32px 72px;
	box-sizing: border-box;
}

.inner {
	position: relative;
	max-width: 100%;
	border-radius: 16px;
}

.closeBtn {
	position: absolute;
	left: 100%;
	top: 16px;
	margin-left: 16px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #040406a3;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 1;

	transition: 0.2s background-color;

	@include hover(hover) {
		&:hover {
			background-color: #ffffffe0;

			.closeIcon {
				fill: #040406;
			}
		}
	}
}

.closeIcon {
	width: 16px;
	height: 16px;
	fill: #fff;
	transition: 0.2s fill;
}

.plate {
	padding: 32px 48px 48px;
	background-color: #fff;
	width: 600px;
	max-width: 100%;
	border-radius: 16px;
	box-sizing: border-box;
}

.title {
	font-size: 30px;
	line-height: 36px;
	font-weight: 700;
	padding-top: 2px;
	padding-bottom: 2px;
	@include mb(32px);
}

.header {
	display: none;
}

.block_state_fullscreen {
	@include state_fullscreen();
}

.block_adapt_default {
	@include breakpoint(null, 's-medium') {
		@include state_fullscreen();
	}
}

.block_adapt_claim {
	.plate {
		padding: 32px;
	}
	.title {
		line-height: 32px;
		padding: 0;
	}
	@include breakpoint(null, 's-medium') {
		@include state_fullscreen();
		.plate {
			padding-top: 24px;
		}
	}
}

.block_adapt_fullscreen {
	@include state_fullscreen();
}

.block_adapt_message {
	.plate {
		width: 600px;
	}

	@include breakpoint(null, 's-medium') {
		@include state_fullscreen();
		.plate {
			padding-top: 72px;
		}
	}
}

.block_adapt_headerForm {
	@include state_headed();
	@include breakpoint($from: 375) {
		.header {
			height: 80px;
			padding-left: 8px;
			padding-right: 8px;
			:global(.logo_main) {
				display: block;
			}
			:global(.logo_small) {
				display: none;
			}
		}
		.closeBtn {
			margin-bottom: 20px;
		}
		.content {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	@include breakpoint($from: 600) {
		.header {
			display: none;
		}
		.content {
			padding-top: 40px;
			padding-left: 64px;
			padding-right: 64px;
		}

		.closeBtn {
			right: auto;
			left: 100%;
			bottom: auto;
			top: 0;
			margin: 0;
			margin-left: 8px;
			margin-top: 8px;
		}
	}
}

.block_adapt_filter {
	@include breakpoint(null, 's-medium') {
		@include state_fullscreen();
		.content {
			background: none;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		.closeBtn {
			bottom: 100%;
			top: auto;
			right: 4px;
			margin-bottom: 4px;
			background: none;
		}
	}
}

.block_animation_default {
	@include appear_animation();
}

.block_overflow {
	.wrap {
		overflow: hidden;
	}
}

// space page stickies hack
:global(.--space-page-sticky) .block {
	@include breakpoint(null, 's-medium') {
		top: var(--sticky-contacts-height);
		&:before {
			content: '';
			position: fixed;
			left: 0;
			right: 0;
			top: var(--sticky-contacts-height);
			z-index: 1;
			height: 1px;
			background: rgba(4, 4, 6, 0.08);
		}
	}
}
