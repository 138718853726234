@mixin state_fullscreen() {
	& {
		align-items: stretch;
		justify-content: stretch;
	}

	.inner {
		width: auto;
		margin-left: auto;
		margin-right: auto;
	}

	.content {
		padding: 0;
		background-color: #fff;
		flex-grow: 1;
		width: 100%;
		overflow: hidden;
	}

	.closeBtn {
		left: auto;
		margin: 0;
		right: 16px;
		top: 16px;
	}

	.plate {
		padding: 16px 24px 32px;
	}

	.title {
		font-size: 26px;
		line-height: 32px;
		padding-right: 40px;
	}

	.closer {
		display: none;
	}
}
