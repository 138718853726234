@mixin appear_animation() {
	@keyframes change_bg {
		0% {
			background: rgba(0, 0, 0, 0);
		}
		100% {
			background: rgba(0, 0, 0, 0.64);
		}
	}

	@keyframes body_appear {
		0% {
			opacity: 0;
			transform: translateY(150px);
		}
		50% {
			opacity: 0;
			transform: translateY(150px);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}

	@keyframes block_overflow {
		0% {
			overflow: hidden;
		}
		99% {
			overflow: hidden;
		}
		100% {
			overflow: auto;
		}
	}

	& {
		animation-name: change_bg;
		animation-duration: 0.3s;
		animation-timing-function: ease;
		animation-fill-mode: forwards;
	}

	.content {
		animation-name: body_appear;
		animation-duration: 0.4s;
		animation-timing-function: ease;
		animation-fill-mode: forwards;
	}

	&:not(.block_overflow) .wrap {
		animation-name: block_overflow;
		animation-duration: 0.4s;
		animation-timing-function: ease;
		animation-fill-mode: forwards;
	}
}
